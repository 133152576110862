<template>
  <div class="reason-social-wrapper">
    <div ref="reason" class="my-reason-wrapper" :class="flyInClass">
      <div class="my-reason">
        <div class="reason">{{ reason }}</div>
      </div>
    </div>
    <social-sharing
      class="fade"
      :class="socialFade"
      :url="meta.url"
      :title="meta.reason"
      :description="meta.reason"
      :twitter-user="meta.twitteruser"
      inline-template
    >
      <div class="social-wrapper">
        <network network="email">
          <i class="envelope-red"></i>
        </network>
        <network network="facebook">
          <i class="facebook-red"></i>
        </network>
        <network network="twitter">
          <i class="twitter-red"></i>
        </network>
      </div>
    </social-sharing>
  </div>
</template>

<script>
// import html2canvas from "html2canvas";
import { mutateMeta, siteInfo } from "../seo/Meta";

export default {
  name: "MyReason",
  props: ["reason", "flyInClass", "socialFade", "image"],
  data() {
    return {
      mutateMeta,
      imgUrl: "",
      meta: {
        url: siteInfo.url,
        reason: this.reason,
        title: siteInfo.title,
        twitteruser: siteInfo.twitteruser
      }
    };
  },
  mounted() {
    // this.takeSnapshot();
    this.updateReason();
    this.updateUrl();
  },
  updated() {
    // this.takeSnapshot();
    this.updateReason();
    this.updateUrl();
  },
  methods: {
    // async uploadImage(slug, imgUrl) {
    //   let response = ReasonService.uploadImage(slug, imgUrl);
    // },
    takeSnapshot() {
      if (this.image === "") {
        this.html2canvas(this.$refs["reason"], {
          allowTaint: true,
          logging: false,
          taintTest: false
        }).then(canvas => {
          this.imgUrl = canvas.toDataURL("image/jpeg", 0.5);
          this.mutateMeta.updateSingle({
            property: "og:image",
            content: this.imgUrl
          });
        });
      }
    },
    updateReason() {
      this.mutateMeta.updateSingle({
        property: "og:description",
        content: this.meta.reason
      });
      this.mutateMeta.updateSingle({
        itemprop: "description",
        content: this.meta.reason
      });
      this.mutateMeta.updateSingle({
        name: "twitter:description",
        content: this.meta.reason
      });
    },
    updateUrl() {
      this.meta.url = siteInfo.url + "/" + this.$route.params.slug;
      this.mutateMeta.updateSingle({
        name: "twitter:site",
        content: this.meta.url
      });
      this.mutateMeta.updateSingle(
        { rel: "canonical", href: this.meta.url },
        "link"
      );
    }
  }
};
</script>