export const consoleAscii = `
                        XXX
                        XXXXXX      X XXXXXX
                      XXX  X        XXX     XXX
                     XX   XX          XX       XX
                   XX    XX            X X       XX
                 XX      X                X       XXX
                XX       X                 XXX      XX
               X         X                   X        XXXXXXX
                                                            XXXXX
           XXXXX                                               X
         XX                X          XXXXXXX               XXXX
         XXXX               XXX       X     XX             XX         - You should probably go take a shower instead of wasting your time digging through code.
            XXX            XX XXXX    XX     XX            XX
              XX         XXX  XXXXX    XXX X XXXX           XXX
             XXX        XXXXXXX                              XXX
           XXX                                             XXX
         XXX                      X       XXXX
          XXXXX         X         XX      XX     XX
                        XX         XXXXXXXX      X
                         XXXXXXXXXXX XXXXXXXXXXXXX
                              X    XX   XXXX   X
                              X   XX X  X   XX X
                              X XX    XXX    XXX
                              XXX     XX
                               X
                      `;
