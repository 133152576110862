<template>
  <div
    class="rf-wrapper fade"
    :class="[fadeToBlack ? 'fade-to-black' : '', fadeIn ? 'fade-in' : 'fade-out']"
    @click.once="firstInteract"
    @keydown="firstInteract"
  >
    <header class="rf-header">
      <a href="//riotfest.org/chicago/tickets" class="rf-logo-tix-link">
        <div class="rf-logo"></div>
      </a>
      <div class="rf-navigation"></div>
    </header>
    <main class="rf-action">
      <section class="rf-body">
        <div class="rf-body-wrapper">
          <div class="container">
            <div class="rf-machine" :class="[machineOn ? 'on' : 'off']">
              <div class="rf-glass">
                <div class="rf-sign" :class="[signOn ? 'sign-on' : 'sign-off']"></div>
                <div
                  :class="[ stamos ? 'its-butter-stamos': 'its-fucking-zolturd',{laugh},{shake}]"
                ></div>
              </div>
              <a
                class="btn-zolturd"
                :class="[hey ? 'hey' : '']"
                @click.prevent="activateZolturd"
                @keydown="activateZolturd"
              >
                <span class="hide">Push Me</span>
              </a>
              <div class="rf-ticket" :class="[dispense ? 'dispense' : '']"></div>
            </div>
          </div>
          <!--.container-->
        </div>
        <!--.wrapper-->
      </section>
      <MyReason
        v-if="newReason.reason !== ''"
        ref="my-reason"
        :reason="newReason.reason"
        :image="newReason.image"
        :fly-in-class="flyIn"
        :social-fade="socialFade"
        :prerendering="prerendering"
      ></MyReason>
      <a
        class="btn-replay fade"
        :class="[replay ? 'fade-in' : 'fade-out hide']"
        @click.prevent="resetZolturd"
        @keydown="resetZolturd"
      >
        <span>Replay</span>
      </a>
    </main>
    <a
      href="#"
      class="btn-mute"
      :class="[mute ? 'mute-on' : 'mute-off']"
      @click.prevent="muteAll"
      @keydown="muteAll"
    >
      <span>Mute</span>
    </a>
    <footer class="rf-footer unplugged"></footer>
    <div class="fog-container">
      <div class="fog-img fog-img-first"></div>
      <div class="fog-img fog-img-second"></div>
    </div>
    <!--.fog-container-->
  </div>
</template>

<script>
import { ReasonService } from "../services/ExpressApi";

import MyReason from "./MyReason.vue";
import router from "../router";

import roar from "../assets/sounds/zolturd-roar.mp3";
import zap from "../assets/sounds/zolturd-eye-zap.mp4";
import magicSound from "../assets/sounds/magic-sound.mp4";
// import allSounds from "../assets/sounds/festar_all_together.mp3";
import breakdown from "../assets/sounds/stamos-breakdown.mp3";
import themeFinale from "../assets/sounds/stamos-theme-finale.mp3";

import { consoleAscii } from "../assets/consoleascii";
import { Vigenere } from "cipherjs";

export default {
  name: "Zolturd",
  components: {
    MyReason
  },
  data() {
    return {
      newReason: {
        url: "",
        reason: "",
        image: ""
      },
      animations: {
        baseAnimation,
        buttonClick,
        loadWithUrl,
        breakDown
      },
      Vigenere,
      consoleAscii,
      stamos: false,
      zolturdButtonActive: false,
      activeAnimation: "",
      mute: false,
      dispense: false,
      fadeToBlack: false,
      laugh: false,
      roaring: false,
      shake: false,
      machineOn: false,
      flyIn: "",
      signOn: false,
      hey: false,
      socialFade: "fade-out",
      replay: false,
      fadeIn: false,
      magic: false,
      prerendering: false,
      soundFiles: {
        roar,
        zap,
        magicSound,
        breakdown,
        themeFinale
      },
      sounds: {}
    };
  },
  watch: {
    roaring: function() {
      if (this.roaring) {
        this.sounds.roar.play();
        this.laugh = true;
      } else {
        this.sounds.roar.pause();
        this.laugh = false;
      }
    },
    signOn: function() {
      this.signOn ? this.sounds.zap.play() : this.sounds.zap.pause();
    },
    magic: function() {
      if (this.magic) {
        this.sounds.magicSound.play();
      }
    }
  },
  mounted() {
    // Just for funsies
    console.log(this.consoleAscii); // eslint-disable-line no-console
    setTimeout(
      function() {
        this.$nextTick(function() {
          this.fadeIn = true;
          if (this.$route.params.slug) {
            this.getReason(this.$route.params.slug);
            this.animate("loadWithUrl");
          }
        });
      }.bind(this),
      400
    );
    Object.keys(this.soundFiles).forEach(key => {
      this.sounds[key] = new Audio(this.soundFiles[key]);
    });
  },
  methods: {
    async activateZolturd() {
      if (this.zolturdButtonActive) {
        this.animate("buttonClick");
        this.zolturdButtonActive = false;
        try {
          const response = await ReasonService.getRandomReason();
          if (response.data.url === "nope") {
            this.sounds.breakdown.play();
            this.animate("breakDown");
          } else {
            this.sounds.themeFinale.play();
          }
          this.newReason.reason = this.decryptAndFormat(
            response.data.reason,
            response.data.url
          );
          this.newReason.url = response.data.url;
          router.replace({ path: "/" + response.data.url });
        } catch (err) {
          console.log("now you've pissed festar offf"); // eslint-disable-line no-console
          console.log(err); // eslint-disable-line no-console
        }
      }
    },
    decryptAndFormat(encoded, url) {
      const alphabet = /[a-z\s]/;
      let reason = "";
      encoded.forEach(segment => {
        if (alphabet.test(segment)) {
          reason += Vigenere.decrypt(segment, url).toLowerCase();
        } else {
          reason += segment;
        }
      });
      return reason;
    },
    firstInteract() {
      this.zolturdButtonActive = true;
      this.animate("baseAnimation");
    },
    resetZolturd() {
      this.sounds.themeFinale.pause();
      this.sounds.themeFinale.currentTime = 0;
      this.replay = false;
      this.fadeToBlack = false;
      this.flyIn = "";
      this.dispense = false;
      this.socialFade = "fade-out";
      router.replace({ path: "/" });
      setTimeout(
        function() {
          this.newReason = {
            url: "",
            reason: "",
            image: ""
          };
        }.bind(this),
        500
      );
    },
    animate(animation) {
      this.activeAnimation = animation;
      let steps = this.animations[animation];
      let ticker = 0;
      let duration = Math.max.apply(
        Math,
        steps.map(function(o) {
          return o.at;
        })
      );
      let interval = setInterval(
        function() {
          for (let index = 0; index < steps.length; index++) {
            let step = steps[index];
            if (step.at === ticker) {
              if (step.do[0] === "state") {
                this[step.do[1]] = step.do[2];
              }
            }
            if (index === steps.length - 1 && ticker === duration) {
              clearInterval(interval);
            }
          }
          ticker += 100;
        }.bind(this),
        100
      );
    },
    pauseAll() {
      this.laugh = false;
      this.shake = false;
      this.roaring = false;
    },
    muteAll() {
      this.mute = this.mute ? (this.mute = false) : (this.mute = true);
      if (this.mute === true) {
        Object.keys(this.sounds).forEach(sound => {
          this.sounds[sound].muted = true;
        });
      } else {
        Object.keys(this.sounds).forEach(sound => {
          this.sounds[sound].muted = false;
        });
      }
    },
    nope() {},
    async getReason(slug) {
      const response = await ReasonService.getReasonBySlug(slug);
      this.newReason.reason = this.decryptAndFormat(response.data.reason, slug);
      this.newReason.url = response.data.url;
    }
  }
};
const breakDown = [
  {
    at: 500,
    do: ["state", "laugh", false]
  },
  {
    at: 500,
    do: ["state", "stamos", false]
  },
  {
    at: 1000,
    do: ["state", "machineOn", false]
  },
  {
    at: 1500,
    do: ["state", "signOn", false]
  },
  {
    at: 1500,
    do: ["state", "shake", false]
  }
];
const baseAnimation = [
  {
    at: 0,
    do: ["state", "fadeIn", true]
  },
  {
    at: 0,
    do: ["state", "machineOn", true]
  },
  {
    at: 500,
    do: ["state", "signOn", true]
  },
  {
    at: 1300,
    do: ["state", "signOn", false]
  },
  {
    at: 1400,
    do: ["state", "signOn", true]
  },
  {
    at: 1500,
    do: ["state", "signOn", false]
  },
  {
    at: 1800,
    do: ["state", "signOn", true]
  },
  { at: 2000, do: ["state", "magic", true] },
  { at: 3000, do: ["state", "magic", false] },
  {
    at: 3000,
    do: ["state", "roaring", true]
  },
  {
    at: 13000,
    do: ["state", "roaring", false]
  },
  {
    at: 11000,
    do: ["state", "hey", true]
  }
];
const loadWithUrl = [
  {
    at: 0,
    do: ["state", "flyIn", "fly-in"]
  },
  {
    at: 0,
    do: ["state", "fadeIn", true]
  },
  {
    at: 2000,
    do: ["state", "fadeToBlack", true]
  },
  {
    at: 6000,
    do: ["state", "laugh", true]
  },
  {
    at: 2000,
    do: ["state", "socialFade", "fade-in"]
  },
  {
    at: 2500,
    do: ["state", "replay", true]
  }
];
const buttonClick = [
  {
    at: 0,
    do: ["state", "hey", false]
  },
  {
    at: 0,
    do: ["state", "zolturdButtonActive", false]
  },
  {
    at: 0,
    do: ["state", "laugh", true]
  },
  {
    at: 0,
    do: ["state", "shake", true]
  },
  {
    at: 2000,
    do: ["state", "stamos", true]
  },
  {
    at: 4000,
    do: ["state", "shake", false]
  },
  {
    at: 4000,
    do: ["state", "laugh", true]
  },
  {
    at: 4000,
    do: ["state", "dispense", true]
  },
  {
    at: 6000,
    do: ["state", "laugh", false]
  },
  {
    at: 6000,
    do: ["state", "fadeToBlack", true]
  },
  {
    at: 6000,
    do: ["state", "flyIn", "fly-in"]
  },
  {
    at: 8000,
    do: ["state", "socialFade", "fade-in"]
  },
  {
    at: 9500,
    do: ["state", "replay", true]
  },
  {
    at: 9500,
    do: ["state", "zolturdButtonActive", true]
  }
];
</script>