import axios from "axios";

let baseURL;

if (window.prerenderer !== undefined && window.prerenderer.active) {
  baseURL = process.env.VUE_APP_BUILD_URL;
} else {
  baseURL = process.env.VUE_APP_URL;
}

export var ExpressApi = axios.create({
  baseURL
});

export var ReasonService = {
  uploadImage(slug, imageFile) {
    return ExpressApi.post("/upload", {
      data: {
        slug,
        imgBase64: imageFile
      },
      headers: {
        "Content-Type": imageFile.type
      }
    });
  },
  getReasonBySlug(slug) {
    return ExpressApi.get("/slug/" + slug);
  },
  getRandomReason() {
    return ExpressApi.get("/reason");
  }
};
